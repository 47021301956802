import React from "react"
import QuizScore from "../components/QuizScore"
import Testimonials from "../components/testimonials"
import SEO from "../components/seo"


const SecondPage = () => (
    <>
        <SEO title="Nurological Quiz Results" />
        <div className="hero-wrapper quiz p-3" >
            {/* <div style={{ margin: '0 auto' }}></div> */}
            <div className="jumbotron jumbotron-fluid with-shadow quiz-wrapper mt-4">
                <QuizScore/>
            </div>
        </div>
        <Testimonials />
    </>
)

export default SecondPage


import React, { Component } from "react"

class Testimonials extends Component {

    constructor(props) {
        super(props);
        this.state = { loadMore: false }
        this.handleClick = this.handleClick.bind(this);
    }

    // function to handle the click
    handleClick() {
      this.setState({
        loadMore : this.state.loadMore ? false : true
      });

        setTimeout(()=>{
            const moreReviews = document.getElementById('moreReviews')
            const lessReviews = document.getElementById('lessReveiws')
            moreReviews ? moreReviews.scrollIntoView({ behavior: 'smooth'}) : lessReviews.scrollIntoView({ behavior: 'smooth'})
        },100)
    };

    // the render() method to put stuff into the DOM
    render() {
        const Testimonials = () => (
            <section className="section-theming bg-purple pt-4" id="testimonials">
                <div className="container p-4">
                    <div className="row pt-4">
                        <div className="col-12 col-lg-6 pt-2">
                            <div className="video-container">
                                <iframe className="video-frame" title="customer testimonial" width="100%" height="315" src="https://www.youtube.com/embed/3u0MaJCIWYk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                        <div className="pt-2 col-12 col-lg-6">
                            <div className="video-container">
                                <iframe className="video-frame" title="customer testimonial" width="100%" height="315" src="https://www.youtube.com/embed/SZ3qvFEQb9w" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-4">
                        <div className="col-12 col-lg-6 pt-4">
                            <div className="video-container">
                                <iframe className="video-frame" title="customer testimonial" width="100%" height="315" src="https://www.youtube.com/embed/J1z_RH3fMTU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>  
                            </div>
                        </div>
                        <div className="pt-4 col-12 col-lg-6">
                            <div className="video-container">
                                <iframe className="video-frame" title="customer testimonial" width="100%" height="315" src="https://www.youtube.com/embed/nXatY_HUfhk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-sm">
                            <div className="review-conatiner">
                                <h3 className="is-purple p-4 font-weight-bold">Jim C.</h3>
                                <p className="is-purple p-4">The ketamine infusions have been a miracle in my quest to overcome depression.
                                    After years of countless medications and therapies, Ketamine Centers &amp; the Chicago IV Solutions
                                    team has allowed me to make that final breakthrough into a normal, happy, productive life. Rather than mask
                                    depression like traditional medications, the ketamine infusions have allowed me to “take a step back” and analyze,
                                    reflect, and heal the negative aspects of my life. It has truly brought me back to normal and I can tell that the effects are lasting.
                                </p>
                            </div>
                            <div className="review-conatiner">
                                <h3 className="is-purple p-4 font-weight-bold">Jason P.</h3>
                                <p className="is-purple p-4">After being diagnosed with clinical depression at a very young age, I spent most of my life trying to find a
                                    depression and anxiety medication that worked for me. While some of the medications brought me relief from my depression, they also came
                                    with side effects that were impacting other parts of my life. After reading a lot of information on the results of ketamine treatments I
                                    decided to give it a shot. I can’t tell you what a difference it has made in my life! It was a very quick and noticeable change in not only
                                    my attitude and overall outlook on life, but it felt great to be excited again to go and do things with my wife and friends. I can’t recommend
                                    the folks at IV Solutions and ketamine treatments enough to anyone battling depression and anxiety. Without trying to sound too cliche, its
                                    literally changed my life!
                                </p>
                            </div>
                        </div>
                        <div className="col-sm mt-16-desktop">
                            <div className="review-conatiner">
                                <h3 className="is-purple p-4 font-weight-bold">Dena</h3>
                                <p className="is-purple p-4">I have suffered from severe anxiety since childhood. In the past few years the anxiety had turned to severe, painful depression.
                                    I felt like I had been robbed of a normal life, when I reflected on the past all I could remember was constant, agonizing panic and
                                    looking forward seemed unbearable because that was all I could foresee for the future. Each day became just another thing to dread.
                                    When I heard Dr. Nandra being interviewed on the radio describing the science behind ketamine treatments I was excited that there might
                                    be hope for me Dr. Nandra truly listened to me and I could feel that he really wanted to help me. My treatment consisted of six infusions
                                    over a two-week period. I felt slightly better after just one infusion. Since completing the infusions I can honestly say that I am a different person.
                                    I am able to live in the moment and I feel like a normal person for the first time ever. I am writing this testimonial because I am hopeful that other
                                    people who are suffering will seek help.
                                </p>
                            </div>
                            <div className="review-conatiner" id="lessReveiws">
                                <h3 className="is-purple p-4 font-weight-bold">Steve G</h3>
                                <p className="is-purple p-4">I recently went through 3 rounds of high dose chemotherapy to treat a curable form of cancer. Fortunately, the cancer was cured,
                                    but I was suffering from painful neuropathy in my lower legs and feet and also episodic depression as a result of the high dosage chemotherapy. I came across
                                    Dr. Nandra's low dosage Ketamine treatment through researching treatment options and decided to give it a try. Well, the results have exceeded my expectations -
                                    not only has my neuropathy pain been significantly reduced, the symptoms of depression have lifted and I feel terrific! I wholeheartedly endorse this form of
                                    treatment and would recommend to anyone suffering the after-effects of chemotherapy.
                                </p>
                            </div>
                        </div>
                    </div>
                    {
                        !this.state.loadMore &&
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-secondary load-more mb-4" onClick={this.handleClick} >Show More</button>
                        </div>
                    }
                    { this.state.loadMore && <div className="row collapsed">
                        <div className="col-sm collapsed">
                            <div className="review-conatiner collapsed" id="moreReviews">
                                <h3 className="is-purple p-4 font-weight-bold">John Vincent.</h3>
                                <p className="is-purple p-4">I have suffered from depression, OCD and anxiety since I was a teenager. I’ve been seeing psychiatrists since I was 18 years old and
                                that has helped me tremendously. However, adding ketamine to my treatment plan has been an absolute game changer and it has been the single biggest impact on my
                                mental health. After my first treatment with Dr. Bal Nandra, the world has just looked and felt different.  I no longer have suicidal thoughts and have feelings
                                of deep peace and calmness. My view on life is now realistic and not triggered by depressive thoughts; I realize there are good days and bad days and my outlook
                                is positive. I can truly say Dr. Bal saved my life and for that, I’m forever grateful.
                                </p>
                            </div>
                            <div className="review-conatiner">
                                <h3 className="is-purple p-4 font-weight-bold">Megan</h3>
                                <p className="is-purple p-4">Dr. Nandra is the preeminent healthcare provider. He is kind, smart, empathic, non-judgmental. If you have the opportunity to go through
                                this treatment, seize the opportunity. If you're like me, you have known the weight of depression and anxiety for years...decades even. When I first came to see Dr. Nandra,
                                I was mentally and physically exhausted from 18 years of dealing with depression, anxiety, and OCD. Numerous psychologists, psychiatrists, and medications had only numbing effects.
                                Prozac, citalopram, venlafaxine, trazodone, and lorazepam helped quell and quiet the symptoms, but a deeply rooted pain followed me. Life was gray and heavy, void of color. I was
                                skeptical of ketamine infusions, but it came highly recommended by a trusted family member. I did not notice the symptoms disappearing with each infusion, but I noticed myself
                                doing things I had not done in a long time. I started enjoying music again--hearing the harmonies. I began to enjoy the laughter of strangers on the street. Nothing big happened
                                at first, but a series of small changes led to the big realization that I was enjoying my life and the people in it for the first time in years.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm mt-16-desktop">
                            <div className="review-conatiner">
                                <h3 className="is-purple p-4 font-weight-bold">S.P.</h3>
                                <p className="is-purple p-4">My daughter struggled throughout high school with major clinical depression.  After trying numerous antidepressants and years of therapy she found a 
                                combination that kept her going “just enough” to embark on college.  Unfortunately, after a couple of years the depression came back in full force.  More therapy and a medication 
                                adjustment only took her so far, and she was nervous that she would need to drop out of school.  Having researched Ketamine treatment for depression, we decided to give it a go.
                                Even after two infusions my daughters’ depression just lifted.  It was such a relief for her to get a break from the fog of sadness, with no ability to concentrate on anything, 
                                and to just feel like her “normal” self.  After six infusions she went back to college feeling centered, happy and confident.  As warned, the depression did come back after a few weeks, 
                                although not as bad as before.  She has just had another two infusions and is feeling good.  I suspect that she will need to continue with occasional top ups for quite a while.  
                                But I have to say, this really is the easiest treatment for depression she has ever had. For my daughter Ketamine treatments, have allowed her to live the life she wants to lead, 
                                and is fully capable of leading without the relentlessness of depression.  The relief she feels knowing that there is a treatment which works, and most importantly works quickly and 
                                effectively, has been life changing for her. Dr. Nandra and his team were professional, kind, and deeply sympathetic throughout the whole process.  I would recommend them absolutely.
                                </p>
                            </div>
                            <div className="review-conatiner">
                                <h3 className="is-purple p-4 font-weight-bold">M.S.</h3>
                                <p className="is-purple p-4">After struggling with depression for over 25 years I had hit the lowest point in my life. It was difficult to get out of bed each day; I was struggling 
                                at work, and unable to enjoy life with family and friends. Multiple anti-depressants, anti-psychotics, and electroconvulsive therapy provided no relief. After seeing a TED Talk and 
                                reading numerous articles about Ketamine, I decided it was time to take a new approach to curing my depression. Before undergoing my first infusion, Dr. Nandra and his staff took the 
                                time to thoroughly answer all of my questions and walk me through what the process would be like. After the second infusion, I was able to notice a marked decrease in my depression. 
                                I felt happier, slept better, was more social, and had more energy. These improvements continued over the course of the next four infusions. It has now been three weeks since my last 
                                session and I still feel great! Everyone at IV Solutions, Dr. Nandra, the nurses, and the support staff were wonderful to have by my side while undergoing ketamine treatment.
                                </p>
                            </div>
                        </div>
                    </div>
                    }
                    {
                        this.state.loadMore &&
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-secondary load-more mb-4" onClick={this.handleClick} >Show Less</button>
                        </div>
                        

                    }
                </div>

            </section>
        )
        return (
            <Testimonials />
        );
    }
}

export default Testimonials;

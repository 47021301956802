import React, { Component } from "react"
import { Link } from "gatsby"

class QuizResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quizData: {
                name: typeof window !== `undefined` ? window.sessionStorage.getItem('name') : "there",
                score: typeof window !== `undefined` ? parseInt(window.sessionStorage.getItem('score')) : "Oops..something went wrong",
            },
            quizResults: {
                poor: 'It looks like your neurological state is severely out of balance and it’s having a significant negative impact on your quality of life and overall health. We recommend you start by contacting us to do an initial evaluation without delay.',
                fair: 'It looks like your neurological state is getting progressively out of balance and it’s starting to have a noticeable negative effect on your everyday life. We recommend you start by contacting us to do an initial evaluation without delay.',
                moderate: 'It looks like your neurological state may be getting out of balance and you are not feeling 100% these days. We recommend you start by contacting us to do an initial evaluation without delay.',
                good: 'It looks like your neurological state is balanced and you are likely more often than not in a good mood health. Congratulations! You may still find it useful to review information on the rest of our website and of course, contact us if you any questions at all.',
            }
        }
    }


    render() {
        const { quizData: {name, score}, quizResults } = this.state
        return (
            <div className="results-wrapper">
                <h1 className="is-purple font-weight-bold fot m-0 pb-4 text-center">Hi {name}, your neurological health score is</h1>
                <div className="d-flex flex-column align-items-center">
                    {
                        score >= 80 &&
                        <div className="score mb-3 with-shadow good">
                            <h2 className="is-purple font-weight-bold p-4 text-center font-size-42">{score}/100</h2>
                        </div>
                    }
                    {
                        score < 80 && 
                        <div className="score mb-3 with-shadow">
                            <h2 className="is-purple font-weight-bold p-4 text-center font-size-42">{score}/100</h2>
                        </div>
                    }

                    <p className="is-purple font-weight-bold m-0 pb-4" style={{textAlign: 'center'}}>
                        {
                            score < 30 && quizResults.poor
                        }
                        {
                            score >= 30 && score <= 49 && quizResults.fair
                        }
                        {
                            score >= 49 && score <= 79 && quizResults.moderate
                        }
                        {
                            score >= 80 && quizResults.good
                        }
                    </p>
                </div>
                <div className="d-flex justify-content-center" >

                    <Link className="btn btn-success  ml-4 p-3" to="/">Schedule a Consultation</Link>
                    <a className="btn btn-secondary ml-4 p-3" href="/"><div className="font-weight-bold">or Call (844) 687-3646</div></a>
                </div>
            </div>
        )
    }
}

export default QuizResult
